<template>
    <div class="contentHolderGroup">
        
        <div class="formHolder">
            <Input name="Company name" placeholder="Company name" v-model="item.name" :error="errors.name"/>
            <Input name="MC #" placeholder="MC #" v-model="item.mcNumber" :error="errors.mcNumber"/>
            <Input name="DOT" placeholder="DOT" v-model="item.dotNumber" :error="errors.dotNumber"/>
            <Input name="Phone" placeholder="Phone" v-model="item.phone" :error="errors.phone"/>
            <Input name="Invoice email address" placeholder="Invoice email address" v-model="item.invoiceEmail" :error="errors.invoiceEmail"/>
        </div>

        <div class="actionButtons">
            <button class="default medium" @click="handleProfileUpdate" :disabled="isLoading(['handleProfileUpdate'])">{{ isLoading(['handleProfileUpdate']) ? 'Saving' : 'Save changes' }}</button>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                item: {},
                errors: {}
            }
        },
        computed: {
            ...mapGetters(['user'])
        },
        watch: {
            user: {
                deep: true,
                handler() {
                    this.item = JSON.parse(JSON.stringify(this.user));
                }
            }
        },
        methods: {
            handleProfileUpdate() {
                this.errors = {}
                this.ajax('handleProfileUpdate', {
                    url: '/profile',
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$emit('updateProfile');
                    this.$notify({
                        type: 'info',
                        text: `Your profile has been updated successfully.`
                    });
                });
            }
        },
        mounted() {
            this.item = JSON.parse(JSON.stringify(this.user));
        }
    }
</script>

<style lang="scss" scoped>
.contentHolderGroup {
    display: grid;
    row-gap: 20px;
}

.formHolder {
    display: grid;
    row-gap: 15px;
}

.actionButtons {
    display: grid;
    justify-content: end;
}
</style>