<template>
    <div class="contentHolderGroup">
        
        <div class="formHolder">
            <Input name="Password" type="password" placeholder="Password" v-model="item.password" :error="errors.password"/>
            <Input name="Repeat password" type="password" placeholder="Repeat password" v-model="item.repeatPassword" :error="errors.repeatPassword"/>
        </div>

        <div class="actionButtons">
            <button class="default medium" @click="handleProfileUpdate" :disabled="isLoading(['handleProfileUpdate'])">{{ isLoading(['handleProfileUpdate']) ? 'Saving' : 'Save changes' }}</button>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                item: {},
                errors: {}
            }
        },
        methods: {
            handleProfileUpdate() {
                this.errors = {}
                this.ajax('handleProfileUpdate', {
                    url: '/password',
                    method: 'POST',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.item = {};
                    this.$emit('updateProfile');
                    this.$notify({
                        type: 'info',
                        text: `Your password has been changed.`
                    });
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.contentHolderGroup {
    display: grid;
    row-gap: 20px;
}

.formHolder {
    display: grid;
    row-gap: 15px;
}

.actionButtons {
    display: grid;
    justify-content: end;
}
</style>