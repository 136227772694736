<template>
    <div>
        
        <div class="item">
            <h2>Tracking</h2>
            <div class="options">
                
                <div v-if="(!tracking || !tracking.isTracked)">
                    <div>You haven't set up tracking yet.</div>
                    <a href="" @click.prevent="addTracking">Add SAMSARA</a>
                </div>
                <div v-else>
                    <div>Tracking is set up.</div>
                    <a href="" @click.prevent="removeTracking">Remove</a>
                </div>
                 
            </div>
        </div>
        



    </div>
</template>

<script>
import AddTrackingVue from './AddTracking.vue';

    export default {
        data() {
            return {
                tracking: null
            }
        },
        methods: {
            getMyTracking() {
                this.ajax('GetMyTrucking', {
                    url: '/myTracking/details',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.tracking = body;
                });
            },
            removeTracking() {
                this.ajax('RemoveMyTrucking', {
                    url: '/myTracking',
                    method: 'DELETE'
                }, (err, body) => {
                    this.tracking = null;
                });
            },
            addTracking() {

                this.$showModalSimple(AddTrackingVue, 'Add Tracking', {
                    item: {}
                }, (data) => {
                    this.getMyTracking();
                });

            }
        },
        mounted() {
            this.getMyTracking();
        }
    }
</script>

<style lang="scss" scoped>
.item {
    display: grid;
    row-gap: 10px;
}
</style>