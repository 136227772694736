<template>
    <div class="userProfileContent">
        
        <h2>My Profile</h2>

        <div class="headerHolder">
            <div class="tabs">
                <div class="tab" :class="{ 'active' : selectedTab == key }" @click="selectedTab = key" :key="key" v-for="(tab, key) of tabs">
                    <div class="name textNoWrap">{{ tab.name }}</div>
                </div>
            </div>
        </div>

        <div class="content">
            <component :is="tabs[selectedTab].component" @updateProfile="updateUserDetails"/>
        </div>


    </div>
</template>

<script>
import MainInfo from '../components/Profile/Main.vue'
import EmailAddress from '../components/Profile/Email.vue'
import Password from '../components/Profile/Password.vue'
import Connect from '../components/Profile/Connect.vue'
    export default {
        components: {
            MainInfo,
            EmailAddress,
            Password,
            Connect
        },
        data() {
            return {
                tabs: [
                    { name: 'Main information', component: 'MainInfo' },
                    { name: 'Email address', component: 'EmailAddress' },
                    { name: 'Password', component: 'Password' },
                    { name: 'Integrations', component: 'Connect' },
                ],
                selectedTab: 0
            }
        },
        methods: {
            updateUserDetails() {
                this.ajax('GetUserDetails', {
                    url: '/user',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.$store.commit('user', body);
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.userProfileContent {
    padding: 30px 0;
    max-width: 662px;
    margin: 0 auto;
    display: grid;
    row-gap: 30px;
}

.content {
    background: $bgSecondary;
    padding: 20px;
}

.headerHolder {
    border-bottom: 1px solid $borderColor;
    .tabs {
        display: flex;
        .tab {
            padding: 0 20px;
            display: grid;
            grid-template-columns: auto;
            height: 50px;
            align-items: center;
            column-gap: 15px;
            cursor: pointer;
            // border-radius: 5px 5px 0 0;
            transition: ease 0.3s;
            user-select: none;
            .count {
                padding: 5px 7px;
                background: $buttonColor2;
                border-radius: 5px;
                font-size: 12px;
                font-weight: bold;
                transition: ease 0.3s;
            }
            .name {
                font-size: 14px;
                transition: ease 0.3s;
                font-weight: bold;
            }
            &.active {
                background: $buttonColor2;
                color: $buttonColor;
                .count {
                    color: $buttonText;
                    background: $buttonColor;
                }
            }
        }
    }
}
</style>