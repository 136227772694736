<template>
    <div class="modal-grid">
        <Input v-model="tracking.token" name="Samsara API key" placeholder="API key"/>

        <div class="actionBtns">
            <button class="default medium" @click="addTracking" :disabled="isLoading(['AddMyTracking'])">Add tracking</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['options'],
        data() {
            return {
                tracking: this.options.item,
                errors: {}
            }
        },
        methods: {
            addTracking() {
                this.errors = {}
                this.ajax('AddMyTracking', {
                    url: '/myTracking',
                    method: 'POST',
                    data: this.tracking
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!')
                        return;
                    }
                   this.$hideModalSimple({ ok: true });
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.modal-grid {
    display: grid;
    row-gap: 20px;
}
</style>